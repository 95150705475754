import gql from 'graphql-tag'

import shippingTypeFragment from './shipping-type'

const vendorFragment = gql`
  fragment vendor on Vendor {
    id
    originalId
    slug
    seoDescription
    chatScript
    beetrackWidgetKey
    webfontUrl
    depositInstructions
    contactEmail
    culqiPublicKey
    endpoint
    facebook
    favicon
    fiscalName
    refundPolicyUrl
    fiscalAddress
    googleAnalyticsId
    googleAnalytics4Id
    facebookPixelId
    googleTagManagerId
    googleMapsApiKey
    instagram
    youtube
    logo
    alternativeLogo
    minimumOrder
    name
    phone
    primaryColor
    ruc
    secondaryColor
    tawkToId
    whatsapp
    whatsappUrl @client
    ogImage
    loginBg
    legalTermsUrl
    privacyPolicyUrl
    cookiesPolicyUrl
    faqUrl
    openpayMerchantId
    openpayPublicKey
    kushkiMerchantId
    visanetMerchantId
    facebookAppId
    googleClientId
    isPartnerVendor
    deliveryPattern
    settings
    timezone
    mercadoPagoPublicKey
    fintocPublicKey
    hasPedidosyaCredentials
    country {
      id
      name
      code
      callingCode
      currencyCode
      currencyConfig
      currencySymbol
    }
    features {
      slug
      settings
    }
    shippingTypes {
      ...shippingType
    }
  }
  ${shippingTypeFragment}
`

export default vendorFragment
