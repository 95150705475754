import styled from 'styled-components'

import Label from '~/truck/label'
import theme from '~/truck/theme'

export const StyledContainer = styled.div`
  background-color: ${theme.palette.grayscale30};
  padding: ${theme.spacing.cozy * 3}px;
  padding-bottom: ${theme.spacing.cozy}px;
`
export const StyledWarningSpan = styled(Label)`
  display: block;
  padding: ${theme.spacing.cozy}px 0;
`
