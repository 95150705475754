import axios from 'axios'
import cookies from 'isomorphic-cookie'
import { v4 as uuid4 } from 'uuid'

import { channel, cookieNames } from '~/utils/constants'
import isProduction from '~/utils/is-production'

export interface UseFetcherOptions {
  endpoint?: string
}

function useFetcher(options: UseFetcherOptions = {}) {
  const session: Session = cookies.load(cookieNames.SESSION) as Session

  const partnerVendorEndpoint = cookies.load(cookieNames.PARTNER_VENDOR_ENDPOINT) as string

  const endpoint = cookies.load(cookieNames.ENDPOINT) as string

  const headers: Record<string, string> = {
    'X-Request-Id': uuid4(),
    authorization: session ? `Bearer ${session.token}` : '',
    channel,
    endpoint: isProduction ? window.location.hostname : endpoint,
  }

  if (partnerVendorEndpoint) {
    headers['partner-vendor-endpoint'] = partnerVendorEndpoint
  }

  return axios.create({
    baseURL: `${process.env.API}${options.endpoint ?? ''}`,
    headers,
  })
}

export default useFetcher
