import * as React from 'react'
import { Form as FinalForm } from 'react-final-form'
import { Field } from 'react-final-form'
import styled from 'styled-components'

import { useIsMobile } from '~/hooks'
import Button from '~/truck/button'
import Label from '~/truck/label'
import TextArea from '~/truck/text-area'
import theme from '~/truck/theme'
import { defaultFinalFormDecorators } from '~/utils/constants'

import { BulkDialogContent, BulkDialogFooter } from '../bulk-dialog'
import type { FormValues } from '../dialog-start-process-step-content/types'
import { MAX_NUMBER_OF_LINES, parseInputToJSON } from '../dialog-start-process-step-content/utils'
import { onValidate } from '../dialog-start-process-step-content/utils'

import { StyledContainer, StyledWarningSpan } from './elements'

const StyledDescription = styled(Label)`
  margin-bottom: ${props => props.theme.spacing.cozy * 3}px;
`
const StyledTextArea = styled(TextArea)`
  resize: none;
`
const StyledInputContainer = styled.div<{ isMobile: boolean; hasWarning: boolean }>`
  margin-bottom: ${theme.spacing.compact}px;
  ${props =>
    !props.isMobile &&
    `
    margin-bottom: ${theme.spacing.compact * 5}px;
  `}

  ${props =>
    !props.isMobile &&
    props.hasWarning &&
    `
    margin-bottom: 0;
  `}
`

export function BulkOrderingTypeCopyFromExcel(props) {
  const { updateCart } = props

  const isMobile = useIsMobile()

  const [showWarning, setShowWarning] = React.useState(false)

  const onSubmit = (formValues: FormValues) => {
    const { result: inputParsed } = parseInputToJSON(formValues.input)

    updateCart(inputParsed)
  }

  const parseAdmittedValues = value => {
    if (!value) return

    const lines = value.split('\n')

    let endOfSlice = MAX_NUMBER_OF_LINES + 1

    if (lines.length >= endOfSlice) {
      endOfSlice -= 1

      setShowWarning(true)
    }

    return lines.slice(0, endOfSlice).join('\n')
  }

  return (
    <FinalForm<FormValues> onSubmit={onSubmit} validate={onValidate} decorators={defaultFinalFormDecorators}>
      {formProps => (
        <form onSubmit={formProps.handleSubmit} autoComplete="off">
          <BulkDialogContent gutterBottom={!isMobile} gutterXAxis={!isMobile}>
            <StyledContainer>
              <StyledDescription as="p" $textStyle="h5Regular" $color="grayscale70">
                Añade productos masivamente a tu carrito: Anda a Excel, copia la tabla sin incluir las cabeceras y pega
                los valores en la caja de texto. Puedes agregar un máximo de 50 registros.{' '}
              </StyledDescription>
              <StyledDescription as="p" $textStyle="h5Regular" $color="grayscale70">
                Los productos que agregues y se encuentren en tu carrito serán remplazados con los nuevos valores.
              </StyledDescription>
              <StyledInputContainer isMobile={isMobile} hasWarning={showWarning}>
                <Field name="input" parse={parseAdmittedValues}>
                  {props => {
                    return (
                      <StyledTextArea
                        name={props.input.name}
                        value={props.input.value}
                        onChange={props.input.onChange}
                        placeholder={'SKU\tCantidad\nSKU\tCantidad'}
                      />
                    )
                  }}
                </Field>
                {showWarning ? (
                  <StyledWarningSpan as="span" $textStyle="h5Semibold" $color="error">
                    {`Solo se permiten ${MAX_NUMBER_OF_LINES} productos como máximo.`}
                  </StyledWarningSpan>
                ) : null}
              </StyledInputContainer>
              <BulkDialogFooter isMobile={isMobile}>
                <Button
                  color="primary"
                  type="submit"
                  loading={formProps.submitting}
                  disabled={formProps.invalid}
                  style={{ width: isMobile ? '100%' : null }}
                >
                  Agregar al carrito
                </Button>
              </BulkDialogFooter>
            </StyledContainer>
          </BulkDialogContent>
        </form>
      )}
    </FinalForm>
  )
}
