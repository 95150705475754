import { up } from 'styled-breakpoints'
import styled, { css } from 'styled-components'

import PasswordStrength from '~/components/password-strength'
import theme from '~/truck/theme'

// TODO: error styles must be global
const styles = css`
  ${up('lg')} {
    margin: 0 auto;
    padding: 0;
    width: ${theme.spacing.cozy * 45}px;
  }

  > div {
    width: 100%;
  }
`

export const Container = styled.form`
  ${styles}

  ${up('lg')} {
    width: ${theme.spacing.cozy * 45}px;
  }

  > div {
    margin-bottom: ${theme.spacing.comfortable}px;
  }
`

export const FullContainer = styled.form`
  ${styles}

  ${up('lg')} {
    width: 100%;
  }

  > div {
    margin-bottom: ${theme.spacing.relaxed}px;

    > div {
      :last-of-type {
        margin-bottom: 0;
        padding: 0 ${theme.spacing.compact * 3}px;
      }
    }
  }
`
export const StyledPasswordStrength = styled(PasswordStrength)`
  margin: ${theme.spacing.relaxed}px auto;
`
