import gql from 'graphql-tag'

const localityFragment = gql`
  fragment locality on Locality {
    id
    charge
    chargeLabel
    code
    name
    originalId
    parentId
    division
  }
`

export default localityFragment
