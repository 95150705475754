import gql from 'graphql-tag'

import { localityFragment } from '../fragments'

const coverageQuery = gql`
  query coverage($isBuyingInStore: Boolean!) {
    vendor @skip(if: $isBuyingInStore) {
      id
      coverageImage
      coverage {
        ...locality
      }
    }
    buyingInStore @include(if: $isBuyingInStore) {
      id
      coverage {
        ...locality
      }
    }
  }

  ${localityFragment}
`

export default coverageQuery
