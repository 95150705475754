import * as React from 'react'
import { Form as FinalForm } from 'react-final-form'
import { Field as FinalFormField } from 'react-final-form'
import { times } from 'lodash'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'

import { useFormatters, useIsMobile } from '~/hooks'
import Button from '~/truck/button'
import Field from '~/truck/field'
import Input from '~/truck/input'
import Label from '~/truck/label'
import theme from '~/truck/theme'
import { defaultFinalFormDecorators } from '~/utils/constants'

import { BulkDialogContent, BulkDialogFooter } from '../bulk-dialog'
import type { FormValueFieldGroupObject } from '../dialog-start-process-step-content/types'
import { MAX_NUMBER_OF_LINES, onValidateInputs as onValidate } from '../dialog-start-process-step-content/utils'
import { parseInputsToJSON } from '../dialog-start-process-step-content/utils'

import { StyledContainer, StyledWarningSpan } from './elements'

const StyledDescription = styled(Label)<{ $isMobile?: boolean }>`
  margin-bottom: ${theme.spacing.cozy * 3}px;
  ${props =>
    props.$isMobile &&
    `
    margin-bottom: ${theme.spacing.cozy}px;

  `}
`
const StyledFlex = styled.div`
  display: flex;
  gap: ${theme.spacing.cozy * 2}px;
  margin-bottom: ${theme.spacing.cozy}px;
  padding-right: ${theme.spacing.cozy}px;
  width: 100%;
`

const StyledScroll = styled.div<{ isMobile: boolean }>`
  ${props =>
    props.isMobile &&
    `
    max-height:${theme.spacing.comfortable * 10}px;
  `}
  ${up('md')} {
    max-height: ${theme.spacing.cozy * 27}px;
  }
  ${up('xl')} {
    max-height: clamp(${theme.spacing.cozy * 27}px, 28.5vh, ${theme.spacing.cozy * 49}px);
  }

  overflow-y: auto;
`
export function BulkOrderingTypeInsertManually(props) {
  const { updateCart } = props

  const [rows, setRows] = React.useState(5)

  const formatters = useFormatters()

  const isMobile = useIsMobile()

  const addNewRow = () => {
    setRows(rows + 1)
  }

  const onSubmit = (formValues: FormValueFieldGroupObject) => {
    const { result: inputParsed } = parseInputsToJSON(formValues)

    updateCart(inputParsed)
  }

  return (
    <FinalForm<FormValueFieldGroupObject>
      onSubmit={onSubmit}
      validate={onValidate}
      decorators={defaultFinalFormDecorators}
    >
      {formProps => (
        <form onSubmit={formProps.handleSubmit} autoComplete="off">
          <BulkDialogContent gutterBottom={!isMobile} gutterXAxis={!isMobile}>
            <StyledContainer>
              <StyledDescription as="p" $textStyle="h5Regular" $color="grayscale70">
                Añade productos uno a uno a tu carrito: Ingresa el código (sku) del producto y la cantidad. Puedes
                ingresar un máximo de 50 registros.{' '}
              </StyledDescription>
              <StyledDescription as="p" $textStyle="h5Regular" $color="grayscale70" $isMobile={isMobile}>
                Los productos que agregues y se encuentren en tu carrito serán remplazados con los nuevos valores.
              </StyledDescription>
              <div style={{ width: '100%', display: 'flex', paddingBottom: `${theme.spacing.cozy}px` }}>
                <Label $textStyle="h6Semibold" $textAlign="left" style={{ width: '100%' }} $color="grayscale70">
                  Ingrese SKU
                </Label>
                <Label
                  $textStyle="h6Semibold"
                  $textAlign="left"
                  style={{ width: `${theme.spacing.compact * 25}px` }}
                  $color="grayscale70"
                >
                  Cantidad
                </Label>
              </div>
              <StyledScroll isMobile={isMobile}>
                {times(rows, i => (
                  <StyledFlex key={`line${i + 1}`}>
                    <Field name={`line${i + 1}.sku`} placeholder={'Ej: 123456'} style={{ width: '100%' }} />
                    <FinalFormField name={`line${i + 1}.quantity`} parse={formatters.numeric}>
                      {props => (
                        <Input
                          name={props.input.name}
                          value={props.input.value}
                          onChange={props.input.onChange}
                          style={{ width: `${theme.spacing.cozy * 11}px`, textAlign: 'center' }}
                        />
                      )}
                    </FinalFormField>
                  </StyledFlex>
                ))}
              </StyledScroll>
              {rows === MAX_NUMBER_OF_LINES ? (
                <StyledWarningSpan as="span" $textStyle="h5Semibold" $color="error">
                  {`Solo se permiten ${MAX_NUMBER_OF_LINES} productos como máximo.`}
                </StyledWarningSpan>
              ) : (
                <Button.Plain
                  type="button"
                  onClick={addNewRow}
                  color="info"
                  style={{ width: 'auto', backgroundColor: 'transparent', paddingLeft: '0' }}
                >
                  Agregar otra fila
                </Button.Plain>
              )}
              <BulkDialogFooter isMobile={isMobile}>
                <Button
                  color="primary"
                  type="submit"
                  loading={formProps.submitting}
                  disabled={formProps.invalid}
                  style={{ width: isMobile ? '100%' : null }}
                >
                  Agregar al carrito
                </Button>
              </BulkDialogFooter>
            </StyledContainer>
          </BulkDialogContent>
        </form>
      )}
    </FinalForm>
  )
}
