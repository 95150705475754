import React from 'react'
import { Field as FinalFormField } from 'react-final-form'
import currency from 'currency.js'
import { isEmpty, map } from 'lodash'
import { down } from 'styled-breakpoints'
import styled from 'styled-components'

import { useVendor } from '~/hooks'
import useCurrencyConfig from '~/hooks/use-currency-config'
import useLocationSelector from '~/hooks/use-location-selector'
import Field from '~/truck/field'
import Grid from '~/truck/grid'
import Label from '~/truck/label'
import getError from '~/truck/utils/get-error'

const ErrorLabel = styled(Label)`
  display: block;
  margin-left: ${props => props.theme.spacing.comfortable}px;
  margin-top: ${props => props.theme.spacing.compact}px;
`

const StyledGridColumn = styled(Grid.Column)`
  ${down('sm')} {
    margin: ${props => props.theme.spacing.cozy}px;
  }
`

const getChargeLabel = options => {
  const { location, currencyConfig, hideDefaultChargeLabel } = options

  const { charge, chargeLabel } = location

  if (chargeLabel) {
    return `(${chargeLabel})`
  }

  if (charge && !hideDefaultChargeLabel) {
    const formattedCharge = currency(charge, currencyConfig).format()

    return `(${formattedCharge} envío)`
  }

  return ''
}

const DivisionSelect = props => {
  const { name, fieldName, fieldValue, onDivisionChange, localities, divisionsLength, currentDivisionIndex } = props

  const currencyConfig = useCurrencyConfig()

  const vendor = useVendor()

  React.useEffect(() => {
    onDivisionChange(fieldValue, fieldName)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isLastDivisionLevel = currentDivisionIndex === divisionsLength - 1

  function renderOption(locality) {
    return (
      <option key={locality.id} value={locality.id}>
        {`${locality.name} ${getChargeLabel({
          location: locality,
          currencyConfig,
          hideDefaultChargeLabel: vendor.settings?.shipping?.hideDefaultChargeLabel,
        })}`}
      </option>
    )
  }

  return (
    <StyledGridColumn key={name}>
      <Field
        as="select"
        name={fieldName}
        prompt={name}
        onChange={e => onDivisionChange(e.target.value, fieldName)}
        disabled={isEmpty(localities)}
      >
        {map(localities, renderOption)}
      </Field>

      {isLastDivisionLevel && (
        <FinalFormField name="localityId">
          {({ meta }) => {
            const errorMessage = getError(meta)

            if (!errorMessage) {
              return null
            }

            return (
              <ErrorLabel as="span" $textStyle="h6Regular" $color="error">
                {errorMessage}
              </ErrorLabel>
            )
          }}
        </FinalFormField>
      )}
    </StyledGridColumn>
  )
}

const LocationSelector = props => {
  const { coverageTree } = props

  const [divisions, onDivisionChange] = useLocationSelector(coverageTree)

  const renderDivision = (division, index) => {
    const { fieldName, name, ...rest } = division

    return (
      <DivisionSelect
        fieldName={fieldName}
        key={fieldName}
        name={name}
        onDivisionChange={onDivisionChange}
        divisionsLength={divisions.length}
        currentDivisionIndex={index}
        {...rest}
      />
    )
  }

  return <>{map(divisions, renderDivision)}</>
}

export default LocationSelector
