import * as React from 'react'
import PageVisibility from 'react-page-visibility'
import cookie from 'isomorphic-cookie'
import { split } from 'lodash'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import Router from 'next/router'
import Validate from 'validate.js'

import { analytics } from '~/analytics'
import { analyticsGtagDimensions } from '~/analytics/analytics-gtag'
import AppProvider from '~/components/app-provider'
import StandaloneAppProvider from '~/components/app-provider/standalone'
import GlobalStyle from '~/components/global-style'
import Modal from '~/components/modal'
import { withApp } from '~/hocs/with-app'
import ensureAuthRouting from '~/hocs/with-app/ensure-auth-routing'
import isStorageAvailable from '~/lib/isStorageAvailable'
import MainPopUp from '~/modules/pop-ups/containers/main-pop-up'
import { ageFlashData } from '~/server/flash-data'
import { getParameterValue, hasFlag } from '~/utils'
import { cookieNames } from '~/utils/constants'
import { birthdateValidator } from '~/utils/validators'

import '../styles/global.css'
import '../src/screens/payment/payme-setup/styles.css'

Validate.validators.birthdate = birthdateValidator

const PopUp = dynamic(() => import('~/components/pop-up'), {
  loading: () => null,
  ssr: false,
})

function getCustomFontFamily(fontUrl: string) {
  const parameterValue = getParameterValue('family', fontUrl)

  return split(parameterValue, ':', 1).toString()
}

function RiqraApp(appProps: RiqraAppProps) {
  const { Component, pageProps, vendor, user, err, router } = appProps

  React.useEffect(() => {
    if (!vendor) {
      return
    }

    const routeChangeStart = () => {
      ageFlashData()
    }

    const routeChangeComplete = () => {
      if (!err) {
        analytics.pageView({ vendor })
      }
    }

    analyticsGtagDimensions(vendor, user)

    if (!err) {
      analytics.pageView({ vendor })
    }

    if (isStorageAvailable('localStorage')) {
      localStorage.setItem('debug', process.env.DEBUG)
    }

    Router.events.on('routeChangeStart', routeChangeStart)

    Router.events.on('routeChangeComplete', routeChangeComplete)

    return () => {
      if (!vendor) {
        return
      }

      Router.events.off('routeChangeStart', routeChangeStart)

      Router.events.off('routeChangeComplete', routeChangeComplete)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function onPageVisibilityChange(isVisible: boolean) {
    if (!isVisible || !vendor) {
      return
    }

    const session = cookie.load(cookieNames.SESSION)

    if (!session) {
      return
    }

    const appContext = {
      asPath: router.asPath,
      pathname: router.pathname,
      hasFlag: (slug: string) => hasFlag(vendor.features, slug),
    }

    ensureAuthRouting(session, appContext, { force: true })
  }

  if (!vendor) {
    return (
      <StandaloneAppProvider>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />
        </Head>
        <GlobalStyle $bgColor="white" />
        <Component {...pageProps} err={err} />
      </StandaloneAppProvider>
    )
  }

  const customFontFamily = vendor.webfontUrl ? getCustomFontFamily(vendor.webfontUrl) : undefined

  return (
    <AppProvider {...appProps}>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />
      </Head>
      <GlobalStyle $customFontFamily={customFontFamily} />
      {!Component.standalone && <PopUp />}
      {!Component.standalone && <MainPopUp />}
      <Component {...pageProps} err={err} />
      <Modal.Canvas />
      <PageVisibility onChange={onPageVisibilityChange} />
    </AppProvider>
  )
}

export default withApp(RiqraApp)
