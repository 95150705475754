import React from 'react'
import styled from 'styled-components'

import Label from '~/truck/label'
import theme from '~/truck/theme'
import { getPasswordStrength } from '~/utils'

const Strength = styled.div`
  background-color: ${props => props.color};
  border-radius: ${theme.dimensions.borderRadius}px;
  height: ${theme.spacing.compact}px;
`

const Strengths = styled.div<{ $divisor: number }>`
  align-items: center;
  display: flex;
  justify-content: space-between;

  > * {
    margin: 0 ${theme.spacing.compact}px;
    width: calc(100% / ${props => props.$divisor});
  }
`

const Text = styled(Label)<{ $color: string }>`
  color: ${props => props.$color};
  display: block;
  margin: 0 0 ${theme.spacing.compact}px ${theme.spacing.compact}px;

  :empty {
    height: ${theme.spacing.cozy * 2.25}px;
  }
`

const Container = styled.div`
  flex-basis: 100%;
`

const strengths = [
  {
    text: 'Poco segura',
    color: theme.colors.error,
  },
  {
    text: 'Segura',
    color: theme.colors.warning,
  },
  {
    text: 'Muy Segura',
    color: theme.colors.success,
  },
]

const Levels = props => {
  const { strength } = props

  return (
    <>
      {strengths.map((current, index) => {
        const currentStrength = index + 1

        const color = currentStrength <= strength ? strengths[strength - 1].color : theme.colors.gray

        return <Strength key={current.color} color={color} />
      })}
    </>
  )
}

const PasswordStrength = props => {
  const { value, className } = props

  const strength = getPasswordStrength(value)

  const isValidStrength = strength > 0

  const item = isValidStrength ? strengths[strength - 1] : ({} as any)

  return (
    <Container className={className}>
      <Text as="span" $textStyle="h6Semibold" $color={item.color}>
        {isValidStrength && item.text}
      </Text>
      <Strengths $divisor={strengths.length}>
        <Levels strength={strength} />
      </Strengths>
    </Container>
  )
}

export default PasswordStrength
