import gql from 'graphql-tag'

import { checkoutFragment, paymentMethodFragment, priceLineFragment, shippingTypeFragment } from '../fragments'

const checkoutQuery = gql`
  query checkout($canSelectTimeRange: Boolean!, $withPurchaseOrderStep: Boolean!) {
    checkoutConfig: vendor {
      id
      deliveryDates
      documentTypes {
        id
        name
        slug
      }
      shippingTypes {
        ...shippingType
      }
      paymentMethods {
        ...paymentMethod
        settings
      }
      deliveryTimeRanges @include(if: $canSelectTimeRange) {
        id
        time
      }
      settings
    }
    checkoutState: user {
      id
      email
      lastOrder {
        ...checkout
      }
      currentOrder: cart(runPromotions: true) {
        ...checkout
        originalId
        deliveryCost
        purchaseOrderUrl
        paymentMethod {
          id
          slug
        }
        lineItems {
          id
          price
          quantity
          name
          sku
          photo
          presentation
          product {
            id
            attributesSummary
          }
        }
        hasFreeDelivery @client
        needsPurchaseOrder @include(if: $withPurchaseOrderStep)
        priceLines {
          ...priceLine
        }
        total
      }
    }
  }
  ${checkoutFragment}
  ${shippingTypeFragment}
  ${paymentMethodFragment}
  ${priceLineFragment}
`

export default checkoutQuery
