import React, { useState } from 'react'
import { useField } from 'react-final-form'

import TextField from '~/components/text-field'
import Button from '~/truck/button'
import Grid from '~/truck/grid'
import Icon from '~/truck/icon'

import { Container, FullContainer, StyledPasswordStrength } from './elements'

const Content = props => {
  const { submitting, handleSubmit } = props

  const { input } = useField('newPassword')

  const [showPassword, setShowPassword] = useState(false)

  const onShowPassword = () => setShowPassword(!showPassword)

  const iconType = showPassword ? 'eye' : 'eye-off'

  const inputType = showPassword ? 'text' : 'password'

  const grid = handleSubmit ? 12 : 6

  return (
    <Grid>
      <Grid.Column lg={grid} marginToRight>
        <TextField
          as="input"
          name="newPassword"
          type={inputType}
          label="Nueva contraseña"
          rightItem={<Icon type={iconType} onClick={onShowPassword} />}
        />
      </Grid.Column>
      <Grid.Column lg={grid}>
        <TextField
          as="input"
          name="confirmPassword"
          type={inputType}
          label="Confirmar contraseña"
          rightItem={<Icon type={iconType} onClick={onShowPassword} />}
        />
      </Grid.Column>
      <StyledPasswordStrength value={input.value} />
      {handleSubmit && (
        <Button type="submit" loading={submitting}>
          Cambiar contraseña
        </Button>
      )}
    </Grid>
  )
}

const ChangePassword = props => {
  const { handleSubmit, submitting } = props

  if (handleSubmit) {
    return (
      <Container onSubmit={handleSubmit} submitting={submitting}>
        <Content handleSubmit={handleSubmit} />
      </Container>
    )
  }

  return (
    <FullContainer>
      <Content />
    </FullContainer>
  )
}

export default ChangePassword
