import gql from 'graphql-tag'

import localityFragment from './locality'

const addressFragment = gql`
  fragment address on Address {
    id
    contactPhone
    contactPerson
    line
    apartment
    reference
    isDefault
    locationLine @client
    location {
      ...locality
    }
    coordinates {
      latitude
      longitude
    }
    divisionLevel1
    divisionLevel2
    divisionLevel3
    divisionLevel4
  }
  ${localityFragment}
`

export default addressFragment
